import { createMuiTheme } from '@material-ui/core/styles'
import { zhCN } from '@material-ui/core/locale'

export const darkTheme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#1890ff',
        contrastText: '#fff',
      },
      secondary: {
        main: '#425BA6',
        contrastText: '#fff',
      },
      type: 'dark',
      text: {
        primary: '#fff',
      },
      background: {
        paper: '#202020',
        default: '#090923',
      },
      error: {
        main: '#556cd6',
      },
    },
  },
  zhCN
)

export const lightTheme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#1890ff',
        contrastText: '#fff',
      },
      secondary: {
        main: '#425BA6',
        contrastText: '#000',
      },
      type: 'light',
      text: {
        primary: '#000',
      },
      background: {
        paper: '#fff',
        default: '#090923',
      },
      error: {
        main: '#556cd6',
      },
    },
  },
  zhCN
)
