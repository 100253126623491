import React, { useReducer, createContext } from 'react'
import { Map } from 'immutable'

const Context = createContext()

export default Context

const initialState = {
  payments: {},
  settings: {
    notification: {},
    enterprise_user_remarks: {},
  },
  users: {},
  self: {},
  info: {},
  view: {
    referrerName: '',
    createDialogOpen: false,
    searchText: '',
  },
}
const actions = {
  PAYMENTS_SET: 'PAYMENTS_SET',
  VIEW_SET_IN: 'VIEW_SET_IN',
  SELF_SET_IN: 'SELF_SET_IN',
  SETTINGS_MERGE: 'SETTINGS_MERGE',
  USERS_MERGE: 'USERS_MERGE',
  SELF_SET: 'SELF_SET',
  VIEW_SET: 'VIEW_SET',
  INFO_SET: 'INFO_SET',
  USERS_SET: 'USERS_SET',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.PAYMENTS_SET:
      return { ...state, payments: Map(action.value) }
    case actions.INFO_SET:
      return { ...state, info: Map(action.value) }
    case actions.VIEW_SET_IN:
      return { ...state, view: Map(state.view).setIn(action.path, action.value) }
    case actions.SELF_SET_IN:
      return { ...state, view: Map(state.self).setIn(action.path, action.value) }
    case actions.SELF_SET:
      return { ...state, self: Map(action.value) }
    case actions.USERS_SET:
      return { ...state, users: Map(action.value) }
    case actions.USERS_MERGE:
      return { ...state, self: Map(state.users).merge(action.value) }
    case actions.SETTINGS_MERGE:
      return { ...state, settings: Map(state.settings).merge(action.value) }
    default:
      return state
  }
}

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const contextValue = {
    payments: Map(state.payments),
    self: Map(state.self),
    info: Map(state.info),
    users: Map(state.users),
    view: Map(state.view),
    settings: Map(state.settings),
    paymentsSet: (value) => {
      dispatch({ type: actions.PAYMENTS_SET, value })
    },
    selfSet: (value) => {
      dispatch({ type: actions.SELF_SET, value })
    },
    infoSet: (value) => {
      dispatch({ type: actions.INFO_SET, value })
    },
    usersMerge: (value) => {
      dispatch({ type: actions.USERS_MERGE, value })
    },
    settingsMerge: (value) => {
      dispatch({ type: actions.SETTINGS_MERGE, value })
    },
    viewSetIn: (path, value) => {
      dispatch({ type: actions.VIEW_SET_IN, path, value })
    },
    selfSetIn: (path, value) => {
      dispatch({ type: actions.DATA_SET_IN, path, value })
    },
    usersSet: (value) => {
      dispatch({ type: actions.USERS_SET, value })
    },
  }
  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}
